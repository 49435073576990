import { toLocaleFromString } from '@peloton/internationalize';
import type { Locale } from '@peloton/internationalize';
import type { Time } from '@peloton/time';
import { getLocale as getLocaleFromTime, formatDate } from '@peloton/time';
import {
  DateFormat,
  getFormatStringsForLocale,
  getInfoForLocale,
  TimeFormat,
} from './dateTimeUtils';
import type { FormatMap } from './dateTimeUtils';
import { SupportedFormat } from './SupportedFormat';

const getTimeFormatForLocale = (locale: Locale): TimeFormat =>
  getInfoForLocale(locale).timeFormat;
const getDateFormatForLocale = (locale: Locale): DateFormat =>
  getInfoForLocale(locale).dateFormat;

const formatLocalizedDate = (
  date: Time,
  format: SupportedFormat,
  getLocale?: LocaleGetter,
) =>
  formatDate(
    date,
    getFormatStringsForLocale(toLocaleFromString(getLocaleOrDefault(getLocale)()))[
      format
    ],
  );

const getLocaleOrDefault = (getLocale?: LocaleGetter): LocaleGetter =>
  typeof getLocale === 'function' ? getLocale : getLocaleFromTime;

export const isTwelveHourTimeFormat = (getLocale?: LocaleGetter): boolean =>
  getTimeFormatForLocale(toLocaleFromString(getLocaleOrDefault(getLocale)())) ===
  TimeFormat.TwelveHour;
export const isTwentyFourHourTimeFormat = (getLocale?: LocaleGetter): boolean =>
  getTimeFormatForLocale(toLocaleFromString(getLocaleOrDefault(getLocale)())) ===
  TimeFormat.TwentyFourHour;
export const isMonthDayYearFormat = (getLocale?: LocaleGetter): boolean =>
  getDateFormatForLocale(toLocaleFromString(getLocaleOrDefault(getLocale)())) ===
  DateFormat.MonthDayYear;
export const isDayMonthYearFormat = (getLocale?: LocaleGetter): boolean =>
  getDateFormatForLocale(toLocaleFromString(getLocaleOrDefault(getLocale)())) ===
  DateFormat.DayMonthYear;
export const toLocalizedAbbreviatedLong = (date: Time, getLocale?: LocaleGetter) =>
  formatLocalizedDate(date, SupportedFormat.AbbreviatedLong, getLocale);
export const toLocalizedAbrevLongWithoutDay = (date: Time, getLocale?: LocaleGetter) =>
  formatLocalizedDate(date, SupportedFormat.AbrevLongWithoutDay, getLocale);
export const toLocalizedSlashedDate = (date: Time, getLocale?: LocaleGetter) =>
  formatLocalizedDate(date, SupportedFormat.SlashedDate, getLocale);
export const toLocalizedSlashedDateLongYear = (date: Time, getLocale?: LocaleGetter) =>
  formatLocalizedDate(date, SupportedFormat.SlashedDateLongYear, getLocale);
export const toLocalizedTwoDigitMonthSlashedDate = (
  date: Time,
  getLocale?: LocaleGetter,
) => formatLocalizedDate(date, SupportedFormat.TwoDigitMonthSlashedDate, getLocale);
export const toLocalizedDayNameMonthDate = (date: Time, getLocale?: LocaleGetter) =>
  formatLocalizedDate(date, SupportedFormat.DayNameMonthDate, getLocale);

export const toLocalizedDayNameShortMonthDate = (date: Time, getLocale?: LocaleGetter) =>
  formatLocalizedDate(date, SupportedFormat.DayNameShortMonthDate, getLocale);

export const toLocalizedDayNameShort = (date: Time, getLocale?: LocaleGetter) =>
  formatLocalizedDate(date, SupportedFormat.DayNameShort, getLocale);
export const toLocalizedMonthNameShortDate = (date: Time, getLocale?: LocaleGetter) =>
  formatLocalizedDate(date, SupportedFormat.MonthNameShortDate, getLocale);
export const toLocalizedShortMonthDate = (date: Time, getLocale?: LocaleGetter) =>
  formatLocalizedDate(date, SupportedFormat.ShortMonthDate, getLocale);
export const toLocalizedDayFirstLetter = (date: Time, getLocale?: LocaleGetter) =>
  formatLocalizedDate(date, SupportedFormat.DayNameShort, getLocale)[0];
export const toLocalizedDayOfMonth = (date: Time, getLocale?: LocaleGetter) =>
  formatLocalizedDate(date, SupportedFormat.DayOfMonth, getLocale);
export const toLocalizedMonthYear = (date: Time, getLocale?: LocaleGetter) =>
  formatLocalizedDate(date, SupportedFormat.MonthYear, getLocale);
export const toLocalizedDayNameLongMonthSlashDay = (
  date: Time,
  getLocale?: LocaleGetter,
) => formatLocalizedDate(date, SupportedFormat.DayNameLongMonthSlashDay, getLocale);
export const toLocalizedMonthNameYear = (date: Time, getLocale?: LocaleGetter) =>
  formatLocalizedDate(date, SupportedFormat.MonthNameYear, getLocale);
export const toLocalizedTime = (date: Time, getLocale?: LocaleGetter) =>
  formatLocalizedDate(date, SupportedFormat.Time, getLocale);
export const toMonthNameShort = (date: Time, getLocale?: LocaleGetter) =>
  formatLocalizedDate(date, SupportedFormat.MonthNameShort, getLocale);

export type LocaleGetter = () => string;
export type DateTimeInfo = {
  timeFormat: TimeFormat;
  dateFormat: DateFormat;
  formatStrings: FormatMap;
};
