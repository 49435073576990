import { datadogRum } from '@datadog/browser-rum';
import type { RumEvent } from '@datadog/browser-rum';
import {
  commitSHA,
  isCypressTest,
  isDeployPreviewEnv,
  isProdEnv,
  isLocalEnv,
} from '@members/env';

const concatCypressEnv = (env: string) => {
  return isCypressTest() ? env.concat('-cypress') : env;
};

export const getEnvironment = () => {
  if (isProdEnv()) {
    return concatCypressEnv('prod');
  }

  if (isDeployPreviewEnv()) {
    return concatCypressEnv('deploy-preview');
  }

  if (isLocalEnv()) {
    return concatCypressEnv('local');
  }

  return concatCypressEnv('uat');
};

// https://docs.datadoghq.com/real_user_monitoring/guide/identify-bots-in-the-ui/
const botPattern =
  '(googlebot/|bot|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis|lua-resty-http)';
const regex = new RegExp(botPattern, 'i');
const getConditionalSampleRate = (userAgent: string) => (regex.test(userAgent) ? 0 : 100);

type HttpEvent = {
  context: {
    config?: {
      headers?: {
        Authorization?: string;
      };
    };
  };
} & RumEvent;

// Using a type predicate to determine if rum event actually contains headers
// https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
const isHttpEvent = (event: RumEvent | HttpEvent): event is HttpEvent => {
  if ((event?.context?.config as HttpEvent)?.headers) {
    return true;
  }

  return false;
};

export const init = () => {
  datadogRum.init({
    applicationId: 'c798dff1-1fad-46d4-9f5f-4a6ecbf01a6e',
    clientToken: 'pub69b1382dca2b8da5943d086a601f685b',
    site: 'datadoghq.com',
    service: 'web-engage',
    env: getEnvironment(),
    version: commitSHA,
    sampleRate: getConditionalSampleRate(navigator.userAgent),
    trackInteractions: true,
    trackFrustrations: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    beforeSend: (event: RumEvent) => {
      if (isHttpEvent(event)) {
        delete event?.context?.config?.headers?.Authorization;
      }
    },
  });

  datadogRum.startSessionReplayRecording();
};
