// Autogenerated from `packages/@members/copy/models.generated/__model__.ts.hbs`

export const Tiering = {
  CardioEquipmentCount: 'cardioEquipmentCount',
  YouHaveSingularPlural: 'youHaveSingularPlural',
  CardioEquipmentClassesAvailableMonth: 'cardioEquipmentClassesAvailableMonth',
  CardioEquipmentClassesAvailable: 'cardioEquipmentClassesAvailable',
  CardioEquipmentClasses: 'cardioEquipmentClasses',
  TakeClassesWithAnyEquipmentPlatform: 'takeClassesWithAnyEquipmentPlatform',
  TakeClassesWithAnyEquipment: 'takeClassesWithAnyEquipment',
  NoQuotaLimitRefreshDate: 'noQuotaLimitRefreshDate',
  QuotaLimitRefreshDate: 'quotaLimitRefreshDate',
  UpgradeToAppPlusNoLimit: 'upgradeToAppPlusNoLimit',
  GotIt: 'gotIt',
  Upgrade: 'upgrade',
  UpgradeToStart: 'upgradeToStart',
  UpgradeToAppPlus: 'upgradeToAppPlus',
  Close: 'close',
  UpgradeUpsellStrength: 'upgradeUpsellStrength',
  UpgradeUpsellCycling: 'upgradeUpsellCycling',
  UpgradeUpsellExclusive: 'upgradeUpsellExclusive',
  UpgradeUpsellMetrics: 'upgradeUpsellMetrics',
  UpgradeToUnlockEntireExperience: 'upgradeToUnlockEntireExperience',
  ThisUsesOneClass: 'thisUsesOneClass',
  ClassesUsedOfTotal: 'classesUsedOfTotal',
  YouCurrentlyHaveSingularPlural: 'youCurrentlyHaveSingularPlural',
  ClassesUsedOfCardioEquipment: 'classesUsedOfCardioEquipment',
  ZeroClassesLeftThisMonth: 'zeroClassesLeftThisMonth',
  JoinClass: 'joinClass',
  Start: 'start',
  DontShowAgain: 'dontShowAgain',
  NotAbleToTakeEveryClassProgram: 'notAbleToTakeEveryClassProgram',
  ProgramIncludesCardioEquipmentClasses: 'programIncludesCardioEquipmentClasses',
  StartProgram: 'startProgram',
  NotAbleToTakeEveryClassChallenge: 'notAbleToTakeEveryClassChallenge',
  ChallengeIncludesCardioEquipmentClasses: 'challengeIncludesCardioEquipmentClasses',
  JoinChallenge: 'joinChallenge',
  ThisClassIsExclusive: 'thisClassIsExclusive',
  Exclusive: 'exclusive',
  DisplayNameFree: 'displayNameFree',
  DisplayNameApp: 'displayNameApp',
  DisplayNameAppPlus: 'displayNameAppPlus',
  DisplayNameGuide: 'displayNameGuide',
  DisplayNameAllAccess: 'displayNameAllAccess',
  And: 'and',
  UpgradeToTakeClass: 'upgradeToTakeClass',
  ExclusiveUpgradeModalDescription: 'exclusiveUpgradeModalDescription',
  FreePaywallTitle: 'freePaywallTitle',
  FreePaywallDescription: 'freePaywallDescription',
  FreePaywallCTA: 'freePaywallCTA',
  TryPelotonAppPlus: 'tryPelotonAppPlus',
  UpgradePelotonAppPlus: 'upgradePelotonAppPlus',
  TryPelotonAppOne: 'tryPelotonAppOne',
  FreeFor: 'freeFor',
  UpgradePelotonAppOne: 'upgradePelotonAppOne',
  StartingAt: 'startingAt',
  StartOneMonthTrial: 'startOneMonthTrial',
  StartTrail: 'startTrail',
  TryMembership: 'tryMembership',
  ThousandsOfClasses: 'thousandsOfClasses',
  MoreClasses: 'moreClasses',
  MoreStrength: 'moreStrength',
  MoreYoga: 'moreYoga',
  MoreMeditation: 'moreMeditation',
  MoreCardio: 'moreCardio',
  MoreStretching: 'moreStretching',
  MoreCycling: 'moreCycling',
  MoreOutdoor: 'moreOutdoor',
  MoreRunning: 'moreRunning',
  MoreWalking: 'moreWalking',
  MoreTreadBootcamp: 'moreTreadBootcamp',
  MoreBikeBootcamp: 'moreBikeBootcamp',
  MoreRowBootcamp: 'moreRowBootcamp',
  MoreRowing: 'moreRowing',
  TryPelotonAppOneNyAppPromo: 'tryPelotonAppOneNyAppPromo',
  AfterPelotonAppOneNyAppPromo: 'afterPelotonAppOneNyAppPromo',
} as const;
