import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import type { ReactReduxContextValue } from 'react-redux';
import { Route } from 'react-router-dom';
import { CDNContext } from '@peloton/cloudinary';
import { InjectIntlContext } from '@peloton/internationalize';
import type { toStore } from '@peloton/redux';
import { ConnectedSwitch, history } from '@peloton/redux';
import ScrollToTop from '@peloton/scroll/ScrollToTop';
import { AcceptTermsModal } from '@members/accept-terms';
import { BootupEvents } from '@members/analytics/bootupEvents';
import { UserFetcher } from '@members/auth';
import { BrazeModal } from '@members/braze';
import { CopyProvider, PseudoProvider, useCopyClient } from '@members/copy';
import { Oops } from '@members/errors';
import { Overlay } from '@members/feature-toggles';
import { GraphqlProvider } from '@members/graphql';
import { Page, PageTitle, baseRoutes } from '@members/layout';
import { Internationalize, LocaleSetter } from '@members/localization';
import { LanguageModal } from '@members/modals/LanguageModal';
import { GlobalFreeTrialBanner } from '@members/notifications/FreeTrialBanner/GlobalBanner';
import { useOneTrust } from '@members/onetrust/useOneTrust';
import { ParallelDependencyFetcher } from '@members/parallel-fetcher';
import { PersistGate } from '@members/persist';
import achievementsRoutes from '@members/pg-achievements/routes';
import authRoutes from '@members/pg-auth/Routes';
import challengesRoutes from '@members/pg-challenges/Routes';
import collectionsRoutes from '@members/pg-collections/Routes';
import { feedRoutes } from '@members/pg-feed';
import FeedNotificationsContainer from '@members/pg-feed/components/FeedNotificationsContainer';
import followRoute from '@members/pg-follow/Route';
import goalsRoutes from '@members/pg-goals/routes';
import { homescreenRoutes } from '@members/pg-homescreen';
import PrivacyPreferencesModal from '@members/pg-homescreen/components/PrivacyPreferences/PrivacyPreferencesModal';
import libraryRoutes from '@members/pg-library/Routes';
import { PageNotFound } from '@members/pg-not-found';
import overviewRoutes from '@members/pg-overview/routes';
import preferencesRoutes from '@members/pg-preferences/Routes';
import { LocationPrivacyCheck } from '@members/pg-profile-setup/LocationPrivacy/LocationPrivacyCheck';
import profileSetupRoutes from '@members/pg-profile-setup/Routes';
import programsRoutes from '@members/pg-programs/Routes';
import remoteActivationRoutes from '@members/pg-remote-activation/Routes';
import scheduleRoutes from '@members/pg-schedule/Routes';
import searchRoutes from '@members/pg-search/Routes';
import oneWellnessRoutes from '@members/pg-setup-account/route';
import videoRoutes from '@members/pg-video/Routes';
import vitalityRoutes from '@members/pg-vitality/Routes';
import workoutsRoutes from '@members/pg-workouts/routes';
import shareWorkoutRoutes from '@members/pg-workouts/ShareWorkoutRoutes';
import { useClassLanguage } from '@members/settings';
import { StackModal } from '@members/stacks';
import { TagsModal } from '@members/tags';
import tagRoutes from '@members/tags/Routes';
import { TierProvider } from '@members/tiering/TierProvider';
import { TieringModalViewController } from '@members/tiering/view-controllers/TieringModalViewController';
import { toUserNavRoutes } from './toUserNavRoutes';
import { toWithoutUserNavRoutes } from './toWithoutUserNavRoutes';

const userNavPagesProps = [
  ...achievementsRoutes,
  ...overviewRoutes,
  ...workoutsRoutes,
  ...goalsRoutes,
  followRoute,
];

const toNoUserNavRoutes = [
  ...authRoutes,
  ...baseRoutes,
  ...preferencesRoutes,
  ...scheduleRoutes,
  ...libraryRoutes,
  ...videoRoutes,
  ...tagRoutes,
  ...challengesRoutes,
  ...collectionsRoutes,
  ...programsRoutes,
  ...remoteActivationRoutes,
  ...oneWellnessRoutes,
  ...shareWorkoutRoutes,
  ...profileSetupRoutes,
  ...searchRoutes,
  ...homescreenRoutes,
  ...feedRoutes,
  ...vitalityRoutes,
];

type Props = {
  routerContext: React.Context<ReactReduxContextValue<any>>;
  reduxStore: ReturnType<typeof toStore>;
};

const App: React.FC<React.PropsWithChildren<Props>> = ({ reduxStore, routerContext }) => {
  const { maybeLoadOneTrust } = useOneTrust();

  React.useEffect(() => {
    maybeLoadOneTrust();
  }, []);

  return (
    <Internationalize>
      <InjectIntlContext>
        <ConnectedRouter history={history} context={routerContext}>
          <UserFetcher>
            <TierProvider>
              <PseudoProvider>
                <LocaleSetter>
                  <PersistGate store={reduxStore}>
                    {/* PersistGate must come before ParallelDependencyFetcher */}
                    <ParallelDependencyFetcher
                      results={{
                        classLanguage: useClassLanguage(),
                        copyClient: useCopyClient(),
                      }}
                    >
                      {({ copyClient }) => (
                        <CopyProvider copyClient={copyClient}>
                          <GraphqlProvider>
                            <PageTitle titleSegments={[]} />
                            <ScrollToTop>
                              <CDNContext>
                                <Page>
                                  <LocationPrivacyCheck />
                                  <PrivacyPreferencesModal />
                                  <GlobalFreeTrialBanner />
                                  <BrazeModal />
                                  <TagsModal />
                                  <StackModal />
                                  <FeedNotificationsContainer />
                                  <LanguageModal />
                                  <AcceptTermsModal />
                                  <TieringModalViewController />
                                  <ConnectedSwitch>
                                    {/* Handle 404s first */}
                                    <Route
                                      exact={true}
                                      path="/404"
                                      component={PageNotFound}
                                    />
                                    <Route exact={true} path="/500" component={Oops} />
                                    {...toUserNavRoutes(userNavPagesProps)}
                                    {...toWithoutUserNavRoutes(toNoUserNavRoutes)}
                                  </ConnectedSwitch>
                                </Page>
                              </CDNContext>
                            </ScrollToTop>
                            <BootupEvents />
                            <Overlay />
                          </GraphqlProvider>
                        </CopyProvider>
                      )}
                    </ParallelDependencyFetcher>
                  </PersistGate>
                </LocaleSetter>
              </PseudoProvider>
            </TierProvider>
          </UserFetcher>
        </ConnectedRouter>
      </InjectIntlContext>
    </Internationalize>
  );
};

export default App;
